<template>
    <NuxtLayout name="default">
        <GameMenu name="Da Hood" image="/images/games/icons/da-hood.png" :links="links" />
        <NuxtLayout name="ads">
            <slot />
        </NuxtLayout>
    </NuxtLayout>
</template>

<script setup lang="ts">
const links = [
    { title: 'Skins', to: '/da-hood/skins' },
    { title: 'Trade Calculator', to: '/da-hood/calculate' },
    { title: 'Trade Ads', to: '/da-hood/ads' },
    { title: 'Value Changes', to: '/da-hood/value-changes' },
    { title: 'Leaks', to: '/da-hood/leaks' }
]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Da Hood | RoValue` : 'Da Hood - RoValue'
    }
})

useServerSeoMeta({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Da Hood | RoValue` : 'Da Hood - RoValue'
    }
})
</script>
